import styled from "styled-components";
import Image from "../../../lib/UIBookBrd/Image";

const Navigation__Box__Values__Holder = styled.div`
  display: flex;
  flex-direction: initial;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  height: 550px;

  @media (max-width: 1100px) {
    height: 50vw;
  }
`
const Navigation__Image = styled(Image)<{ isHover: boolean }>`
  min-width: 100%;
  height: 100%;
  transition: 500ms;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  transform: ${({isHover}) => isHover ? 'scale(1.1)' : undefined};
`
const Control__Section__Navigation__Box = styled.div`
  cursor: pointer;
  display: flex;
  min-width: 100%;
  margin-left: -100%;
  height: 100%;
  z-index: 10;

`
const Section__Text = styled.div`
  display: flex;
  font-size: 45px;
  margin: auto;
  color: ${({theme}) => theme.color};
  font-weight: bold;
  text-shadow: 2px 2px 2px ${({theme}) => theme.backgroundColor};;
`
const Navigation__Video = styled.video<{ isHover: boolean }>`
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 500ms;
  transform: ${({isHover}) => isHover ? 'scale(1.2)' : undefined};
  background-color: grey;
`

export const Styled = {
    Section__Text,
    Navigation__Box__Values__Holder,
    Navigation__Image,
    Control__Section__Navigation__Box,
    Navigation__Video
}