import {Styled} from "./Tapes.styles"
import {useTranslation} from "react-i18next";


const Tapes = () => {

    const {t} = useTranslation()

    return (
        <Styled.Page__Hodler>
            <Styled.Content__Holder>
                <Styled.Tapes__Title>
                    {t('lbl_tapes_title').split('<BREAK/>').map((value: string) => (<>{value}<br/></>))}
                </Styled.Tapes__Title>
                <Styled.Studio__Image
                    src={window.location.origin + '/kasety_glowne.jpg'}
                />
                <Styled.Tapes__Text>
                    {t('lbl_tapes_first_paragraph').split('<BREAK/>').map((value: string) => (<>{value}<br/></>))}
                </Styled.Tapes__Text>
                <Styled.Studio__Image
                    src={window.location.origin + '/kasety_2.png'}
                />
                <Styled.Tapes__Text>
                    {t('lbl_tapes_second_paragraph').split('<BREAK/>').map((value: string) => (<>{value}<br/></>))}
                </Styled.Tapes__Text>
                <Styled.Studio__Image
                    src={window.location.origin + '/kasety_3.png'}
                />
                <Styled.Tapes__Text>
                    <span>
                    {t('lbl_tapes_third_paragraph').split('<BREAK/>').map((value: string,) => (<><br/>{value}</>))}
                        <Styled.Tapes__Text__Link
                            target={'_href'}
                            href={'https://www.instagram.com/s115studio/'}
                        >
                        {' '}Instagram
                       </Styled.Tapes__Text__Link>
                        </span>
                </Styled.Tapes__Text>
            </Styled.Content__Holder>
        </Styled.Page__Hodler>
    )
}
export default Tapes