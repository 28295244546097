import styled from "styled-components";
import Image from "../../lib/UIBookBrd/Image";
import Text from "../../lib/UIBookBrd/Text";

const Page__Hodler = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 73px;
  height: auto;
`
const Content__Holder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({theme}) => theme.backgroundColor};
  padding-bottom: 30px;
`
const Studio__Image = styled(Image)`
  width: 100%;
  object-fit: cover;
  object-position: center, 40%;
  height: 400px;

  @media (max-width: 800px) {
    height: 300px;
  }
  @media (max-width: 500px) {
    height: 200px;
  }

`
const Tapes__Text = styled.span`
  font-size: 20px;
  color: ${({theme}) => theme.color};
  padding: 40px 10px;
`
const Tapes__Title = styled(Text)`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding: 40px 10px;
`
const Tapes__Text__Link = styled.a`
  font-size: 20px;
  font-weight: bold;
  color: ${({theme}) => theme.color};
`


export const Styled = {
    Tapes__Text__Link,
    Tapes__Title,
    Tapes__Text,
    Studio__Image,
    Content__Holder,
    Page__Hodler
}